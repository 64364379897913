import { Component, OnInit, Input } from '@angular/core';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  static img: any;

  constructor() { }

  ngOnInit(): void {
  }
  public img = [
    "/assets/Sl4.jpg",
    "/assets/Sl2.jpg",
    "/assets/Sl14.jpg",
    "/assets/Sl3.jpg",
    "/assets/Sl11.jpg",
    "/assets/Sl1.jpg",
    "/assets/Sl5.jpg",
    "/assets/Sl7.jpg",
    "/assets/Sl6.jpg",
    "/assets/Sl12.jpg",
    "/assets/Sl9.jpg",
];

}
