import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MailComponent } from './mail/mail.component';
import { DestinationComponent } from './destination/destination.component';
import { HomeComponent } from './home/home.component';
import { ApartmentsComponent } from './apartments/apartments.component';
import { Ap3Component } from './ap3/ap3.component';
import { Ap4Component } from './ap4/ap4.component';
import { Ap5Component } from './ap5/ap5.component';
import { Ap7Component } from './ap7/ap7.component';
import { Ap8Component } from './ap8/ap8.component';
import { Ap12Component } from './ap12/ap12.component';

const routes: Routes = [ 
  {path:'destination' , component:DestinationComponent},
  {path:'contact', component:MailComponent},
  {path:'apartments', component:ApartmentsComponent},
  {path:'ap3', component:Ap3Component},
  {path:'ap4', component:Ap4Component},
  {path:'ap5', component:Ap5Component},
  {path:'ap7', component:Ap7Component},
  {path:'ap8', component:Ap8Component},
  {path:'ap12', component:Ap12Component},
  {path:'' , component:HomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [DestinationComponent];
