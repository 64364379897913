<div><app-slide [images] = "img"></app-slide></div>
<h2>About Ap-7</h2>
<p id = "top">AP-7 is situated seaward on the second floor.</p>

<p>Apartment consists of:</p>

<p>- big bedroom with one double bed and one extra bed</p>
<p>- another big bedroom with one double bed and one extra bed</p>


<p>- complete bathroom</p>

<p>- big kitchen (with all equipment), and dining place</p>
<p>- big balcony with a sea view (32 square meter)</p>

<p>Accomodation price is dependent on several factors.</p>

<p>For 3-6 persons it is 90 €. Out of season as agreed. All prices include taxes and fees.</p>

<p id = "bottom">Near the apartment parking (of my own) is assured for every car.</p>













