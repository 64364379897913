import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ap7',
  templateUrl: './ap7.component.html',
  styleUrls: ['./ap7.component.css']
})
export class Ap7Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  public img = [
    "/assets/Sl75.jpg",
    "/assets/Sl71.jpg",
    "/assets/Sl74.jpg",
    "/assets/Sl76.jpg",
    "/assets/Sl77.jpg",
    "/assets/Sl78.jpg",
    "/assets/Sl710.jpg",
    "/assets/Sl711.jpg",
    "/assets/Sl712.jpg"];
}
