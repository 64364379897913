<div id=container>

  <img id="slideshowImg" [@trans]="selectedImage" [src]="selectedImage">



</div>
<button [disabled]="control" (click)=leftClick() color="accent" mat-icon-button id="leftButton">
  <mat-icon>keyboard_arrow_left</mat-icon>
</button>
<button [disabled]="control" (click)=rightClick()  mat-icon-button color="accent" id="rightButton">
  <mat-icon>keyboard_arrow_right</mat-icon>
</button>