import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ap5',
  templateUrl: './ap5.component.html',
  styleUrls: ['./ap5.component.css']
})
export class Ap5Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  public img = [
    "/assets/Sl51.jpg",
    "/assets/Sl52.jpg",
    "/assets/Sl55.jpg",
    "/assets/Sl56.jpg",
    "/assets/Sl58.jpg",
    "/assets/Sl59.jpg",
    "/assets/Sl510.jpg",
    "/assets/Sl513.jpg",
    "/assets/Sl514.jpg",
    "/assets/Sl515.jpg",
    "/assets/Sl516.jpg",
    "/assets/Sl517.jpg",
    "/assets/Sl518.jpg"];
}
