<mat-grid-list *ngIf = "!criticalPoint" cols="3" rowHeight="5:3">
    <mat-grid-tile ><img src="/assets/Sl32.jpg" alt="Error loading image" routerLink="/ap3"><p class="ap">Ap-3</p><p class="per">2+1 Person</p></mat-grid-tile>
    <mat-grid-tile ><img src="/assets/Sl41.jpg" alt="Error loading image" routerLink="/ap4"><p class="ap">Ap-4</p><p class="per">2+2 Person</p></mat-grid-tile>
    <mat-grid-tile ><img src="/assets/Sl51.jpg" alt="Error loading image" routerLink="/ap5"><p class="ap">Ap-5</p><p class="per">4+1 Person</p></mat-grid-tile>
    <mat-grid-tile ><img src="/assets/Sl75.jpg" alt="Error loading image" routerLink="/ap7"><p class="ap">Ap-7</p><p class="per">4+2 Person</p></mat-grid-tile>
    <mat-grid-tile ><img src="/assets/Sl81.jpg" alt="Error loading image" routerLink="/ap8"><p class="ap">Ap-8</p><p class="per">4+3 Person</p></mat-grid-tile>
    <mat-grid-tile ><img src="/assets/Sl1221.jpg" alt="Error loading image" routerLink="/ap12"><p class="ap">Ap-12</p><p class="per">8+3 Person</p></mat-grid-tile>
</mat-grid-list>
<mat-grid-list *ngIf = "criticalPoint" cols="2" rowHeight="5:3">
    <mat-grid-tile ><img src="/assets/Sl32.jpg" alt="Error loading image" routerLink="/ap3"><p class="ap">Ap-3</p><p class="per">2+1 Person</p></mat-grid-tile>
    <mat-grid-tile ><img src="/assets/Sl41.jpg" alt="Error loading image" routerLink="/ap4"><p class="ap">Ap-4</p><p class="per">2+2 Person</p></mat-grid-tile>
    <mat-grid-tile ><img src="/assets/Sl51.jpg" alt="Error loading image" routerLink="/ap5"><p class="ap">Ap-5</p><p class="per">4+1 Person</p></mat-grid-tile>
    <mat-grid-tile ><img src="/assets/Sl75.jpg" alt="Error loading image" routerLink="/ap7"><p class="ap">Ap-7</p><p class="per">4+2 Person</p></mat-grid-tile>
    <mat-grid-tile ><img src="/assets/Sl81.jpg" alt="Error loading image" routerLink="/ap8"><p class="ap">Ap-8</p><p class="per">4+3 Person</p></mat-grid-tile>
    <mat-grid-tile ><img src="/assets/Sl1221.jpg" alt="Error loading image" routerLink="/ap12"><p class="ap">Ap-12</p><p class="per">8+3 Person</p></mat-grid-tile>
</mat-grid-list>