<app-slide [images]="img" ></app-slide>
<div id="container">
    <h2>Vaccation in Croatia</h2>
    <p>Solar apartments provide cheap accomodation on Croatian coast. It is situated in Nemira,
        small tourist place close to Omiš (3 km east of Omiš). It has a wonderful
        sea view from all apartments. The location is great because it is very close to the beach (40 meters away),
        nature is breathtaking and overall we assure you that your stay here will be nothing but relaxing. Surrounding
        area is full of pine trees and medicinal herbs, so people with respiratory problems can enjoy natural healing
        factors
        and healing aerosol. Parking is on my private land and it is assured for every car. In the courtyard there are:
        outer shower, grill, and garden with different plants. Grocery is about 100 m away, and about 300 m around there
        are several restaurants and caffe bars. We can arrange for you Extreme Sports & Adrenaline including Canyoning,
        Kayaking,
        Rafting, Ziplining and other activities in Omiš, most of which are done on Cetina river. There are many other
        activities like Hiking, Rock Climbing, different boat excursions... According to your prefereces choose your
        vaccation here to be active, or just relax and enjoy natural beauties of Adriatic Coast! </p>
    <p>Dear Guests, thank you for your
        interest
        towards our accommodation!</p>
</div>