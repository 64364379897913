<div>
    <app-slide [images]="img"></app-slide>
</div>
<h2>About Ap-12</h2>
<p id="top">AP-12 is situated seaward on the first floor (entire floor).</p>

<p>Apartment consists of:</p>

<p>- three big bedrooms (each with double bed and extra bed)</p>


<p>- big bedroom with double bed</p>
<p>- two complete bathrooms</p>

<p>- WC with wash basin</p>
<p>- big kitchen (with all equipment) and dining table</p>

<p>- big balcony with a sea view (39 square meter)</p>

<p>- balcony with a sea view (3 square meter)</p>
<p>Demand price is dependent on several factors.</p>
<p>For 6-11 persons it is 170 €. Out of season as agreed. All prices include taxes and fees.</p>

<p id="bottom">Near the apartment parking (of my own) is assured for every car.</p>

Apartment is consist of:


















