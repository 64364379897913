import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ap12',
  templateUrl: './ap12.component.html',
  styleUrls: ['./ap12.component.css']
})
export class Ap12Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  public img = [
    "/assets/Sl1221.jpg",
    "/assets/Sl122.jpg",
    "/assets/Sl123.jpg",
    "/assets/Sl125.jpg",
    "/assets/Sl126.jpg",
    "/assets/Sl127.jpg",
    "/assets/Sl128.jpg",
    "/assets/Sl129.jpg",
    "/assets/Sl1212.jpg",
    "/assets/Sl1214.jpg",
    "/assets/Sl1215.jpg",
    "/assets/Sl1216.jpg",
    "/assets/Sl1218.jpg",
    "/assets/Sl1219.jpg",
    "/assets/Sl1225.jpg",
    "/assets/Sl1227.jpg",
    "/assets/Sl1228.jpg"];
}
