import { Component, OnInit } from '@angular/core';
import { User } from '../user';
import {EmailService} from '../email.service';
import { NgForm } from '@angular/forms';
import { from } from 'rxjs';

@Component({
  selector: 'app-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.css']
})
export class MailComponent implements OnInit {
  alertt:boolean = false;
  errorMsg = '';

  constructor(private _emailService:EmailService) { }

  ngOnInit(): void {

  }


  userModel = new User('','','');
  onSubmit(form:NgForm){
    console.log(this.userModel);
    this._emailService.sendMail(this.userModel)
    .subscribe(
      data => console.log('Success',data),
      error => this.errorMsg = error.statusText
    )
    this.alertt = true;
    form.resetForm();

  };
  closeAlert(){
    this.alertt = false;
  }


}
