import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { User } from './user';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class EmailService {

  _url = 'http://solar-apartment.com:80/email';
  constructor(private _http:HttpClient) { }

  errorHandler(error:HttpErrorResponse){
    return throwError(error);
  }

  sendMail(user:User){
    return this._http.post<any>(this._url,user)
    .pipe(catchError(this.errorHandler));
  }


  }

