<div id="container">
        <p id="address">Address: Nemira 1/5 Omiš Croatia</p>
</div>
<div class="maps"><iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1109.5944095440161!2d16.713920846353457!3d43.419888502613524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x134a99d045d01d1f%3A0xcd644b9ababe9c9c!2sSolar%20Apartment!5e0!3m2!1sen!2shr!4v1613438529126!5m2!1sen!2shr"
                frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
</div>

<h1>Omiš</h1>

<div class="maps"> <iframe src="https://www.youtube.com/embed/Cnrjm-Le_vw" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
</div>

<div id="container1">
        <p>Omiš is a small town on Dalmatian coast, just 3km away from Solar Apartments. It's famous for its
                unique
                blend of mountain, Cetina river and Adriatic sea. In the area, there are numerous beaches and
                places for
                relaxation. In Omiš you will be able to do a great deal of activities like: Hiking, Biking,
                Canyoning,
                Rafting, Kayaking and so on. It's worth mentioning many restaurants which provide traditional
                and
                authentic Mediterranean cuisine. Omiš has a rich history, dating back to Antique times. Discover
                Fortica
                and
                Mirabela fortesses, learn about Corsairs of Omiš or just take a walk
                through the
                old
                town! </p>
</div>