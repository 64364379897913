import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ap3',
  templateUrl: './ap3.component.html',
  styleUrls: ['./ap3.component.css']
})
export class Ap3Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  public img = [
    "/assets/Sl32.jpg",
    "/assets/Sl31.jpg",
    "/assets/Sl34.jpg",
    "/assets/Sl35.jpg",
    "/assets/Sl36.jpg",
    "/assets/Sl37.jpg",
    "/assets/Sl38.jpg",
    "/assets/Sl310.jpg"];


}
