<div *ngIf="alertt" class="alert alert-success alert-dismissible fade show" role="alert">
    <strong>Email sent successfully</strong>

    <button (click)="closeAlert()" class="btn-close" aria-label="Close">
    </button>
</div>
<div class="container-fluid">

    <div class="forma">
        <form #userForm="ngForm" (ngSubmit)="onSubmit(userForm)" novalidate>
            <p id = "p">Send us an email:</p>
            <div class="alert alert-danger" *ngIf="errorMsg">
                {{errorMsg}}
            </div>
            <div ngModelGroup="Guest">
                <div class="form-group">
                    <label for="name">Name</label>
                    <input disabled [class.is-invalid]="name.invalid && name.touched" required #name="ngModel" name="name"
                        id="name" type="text" class="form-control" [(ngModel)]="userModel.name">
                    <small [class.d-none]="name.valid || name.untouched">Name is required</small>
                </div>
                <div class="form-group">
                    <label for="email">Email</label>
                    <input disabled pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                        [class.is-invalid]="email.invalid && email.touched" #email="ngModel" required id="email"
                        name="email" type="string" class="form-control" [(ngModel)]="userModel.email">
                    <div *ngIf="email.errors && (email.touched)">
                        <small *ngIf="email.errors.required">Email is required</small>
                        <small *ngIf="email.errors.pattern">Wrong Email pattern</small>

                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="message">Message</label>
                <textarea disabled [class.is-invalid]="message.invalid && message.touched" #message="ngModel" required
                    name="emailSend" cols="30" rows="10" class="form-control"
                    [(ngModel)]="userModel.message"></textarea>
                <small [class.d-none]="message.valid || message.untouched">Message required</small>
            </div>
            <button [disabled]="!userForm.form.valid || name.untouched || email.untouched"
                class="btn btn-primary">Submit</button>
        </form>
    </div>
    <div id="info">
        <p>Owner: Suzana Stanić</p>
        <p>Address: Nemira 1/5, 21310 Omiš Croatia</p>
        <p>Email: pagejure@gmail.com</p>
        <p>Phone: +385 21 879 246</p>
        <p>Mobile phone: +385 91 677 1808</p>
    </div>
</div>