<div><app-slide [images] = "img"></app-slide></div>
<h2>About Ap-8</h2>
<p id = "top">AP-8 is situated seaward in the ground floor</p>

<p>Apartment consists of:</p>

<p>- big bedroom with double bed and two extra beds</p>


<p>- big bedroom with double bed and one extra bed</p>
<p>- two complete bathrooms</p>

<p>- big kitchen (with all equipment), and dining place</p>
<p>- big terrace with a sea view (25 square meter)</p>

<p>Accomodation price is dependent on several factors.</p>

<p>For 4-7 persons it is 110 €. Out of season as agreed. All prices include taxes and fees.</p>

<p id = "bottom">Near the apartment parking (of my own) is assured for every car.</p>













