import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatHelpModule } from './mat-help/mat-help.module';
import { HeaderComponent } from './header/header.component';
import { SlideComponent } from './slide/slide.component';
import { HomeComponent } from './home/home.component';
import { DestinationComponent } from './destination/destination.component';
import {FormsModule} from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MailComponent } from './mail/mail.component';
import { ApartmentsComponent } from './apartments/apartments.component';
import { Ap3Component } from './ap3/ap3.component';
import { Ap4Component } from './ap4/ap4.component';
import { Ap5Component } from './ap5/ap5.component';
import { Ap7Component } from './ap7/ap7.component';
import { Ap8Component } from './ap8/ap8.component';
import { Ap12Component } from './ap12/ap12.component';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SlideComponent,
    HomeComponent,
    DestinationComponent,
    MailComponent,
    ApartmentsComponent,
    Ap3Component,
    Ap4Component,
    Ap5Component,
    Ap7Component,
    Ap8Component,
    Ap12Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatHelpModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
