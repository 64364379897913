<div id="container">
    <app-slide [images]="img"></app-slide>
</div>
<h2>About Ap-3</h2>
<p id = "top">AP-3 is situated seaward on the second floor.</p>

<p>Apartment consists of:</p>

<p>- bedroom with double bed and extra bed</p>


<p>- complete bathroom</p>
<p>- kitchen (with all equipment and dining table)</p>

<p>- balcony with a sea view (3 square meter)</p>

<p>Accomodation price is dependent on several factors.</p>

<p>For 2-3 persons it is 60 €. Out of season as agreed. All prices include taxes and fees.</p>

<p id = "bottom">Near the apartment, parking (of my own) is assured for every car.</p>