<div id="container">
    <h2>SOLAR APARTMENT</h2>
    <mat-toolbar color="primary">
        <div>
            <button mat-button routerLink="/">
                <mat-icon>home</mat-icon>
                <span class="logo"> Home</span>
            </button>
        </div>
        <div id="botuni" *ngIf="!Break">
            <button mat-button routerLink="/apartments">
                <span class="logo" >Apartments</span>
            </button>
            <button mat-button routerLink="/destination">
                <span class="logo">Destination</span>
            </button>
            <button mat-button class="item" routerLink="/contact">
                <span class="logo">Contact Us</span>
            </button>

        </div>
    </mat-toolbar>
    <mat-toolbar color="primary" *ngIf="Break">
        <button mat-button routerLink="/apartments">
            <span class="logoS">Apartments</span>
        </button>
        <button mat-button routerLink="/destination">
            <span class="logoS">Destination</span>
        </button>
        <button mat-button routerLink="/contact">
            <span class="logoS">Contact Us</span>
        </button>
    </mat-toolbar>
</div>