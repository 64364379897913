<div><app-slide [images] = "img"></app-slide></div>
<h2>About Ap-4</h2>
<p id = "top">AP-4 is situated seaward on the second floor.</p>

<p>Apartment consists of:</p>

<p>- big bedroom with double bed and two extra beds</p>


<p>- complete bathroom</p>
<p>- kitchen (with all equipment and dining table)</p>

<p>- balcony with a sea view (7 square meter)</p>

<p>Accomodation price is dependent on several factors.</p>

<p>For 2-4 persons it is 70 €. Out of season as agreed. All prices include taxes and fees.</p>

<p id = "bottom">Near the apartment, parking (of my own) is assured for every car.</p>















