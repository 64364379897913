<div><app-slide [images] = "img"></app-slide></div>
<h2>About Ap-5</h2>
<p id = "top">AP-5 is situated seaward on the ground floor.</p>

<p>Apartment consists of:</p>

<p>- big bedroom with double bed and one extra bed</p>


<p>- bedroom with double bed</p>
<p>- complete bathroom with one more wash basin in front of the entrance to the bathroom</p>

<p>- big kitchen (with all equipment), and dining place</p>
<p>- big terrace with a sea view (15 square meter)</p>

<p>Accomodation price is dependent on several factors.</p>

<p>For 3-5 persons it is 80 €. Out of season as agreed. All prices include taxes and fees.</p>

<p id = "bottom">Near the apartment parking (of my own) is assured for every car.</p>

















