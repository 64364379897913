import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ap8',
  templateUrl: './ap8.component.html',
  styleUrls: ['./ap8.component.css']
})
export class Ap8Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  public img = [
    "/assets/Sl81.jpg",
    "/assets/Sl83.jpg",
    "/assets/Sl85.jpg",
    "/assets/Sl86.jpg",
    "/assets/Sl88.jpg",
    "/assets/Sl810.jpg",
    "/assets/Sl811.jpg",
    "/assets/Sl812.jpg",
    "/assets/Sl814.jpg",
    "/assets/Sl815.jpg"];

}
