import { NgModule } from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatGridListModule} from '@angular/material/grid-list';


const MaterialComponents = [MatButtonModule, MatToolbarModule, MatIconModule, MatGridListModule];

@NgModule({
  imports: [MaterialComponents],
  exports: [MaterialComponents]
})
export class MatHelpModule { }
