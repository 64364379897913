import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ap4',
  templateUrl: './ap4.component.html',
  styleUrls: ['./ap4.component.css']
})
export class Ap4Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  public img = [
    "/assets/Sl41.jpg",
    "/assets/Sl42.jpg",
    "/assets/Sl43.jpg",
    "/assets/Sl44.jpg",
    "/assets/Sl45.jpg",
    "/assets/Sl46.jpg",
    "/assets/Sl47.jpg"
];
}
