import { Component, Input, OnInit, EventEmitter } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';



@Component({
  selector: 'app-slide',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.css'],
  animations: [
    trigger('trans', [
      transition('*<=>*', [
        style({ opacity: 0.5}),
        animate('200ms ease')
      ])
    ])
  ]
})
export class SlideComponent implements OnInit {

  constructor() {

   }

  ngOnInit(): void {
    this.selectedImage = this.images[this.imgIndex];
  }
  @Input()
  public images: string | any[] = ["/assets/Sl1.jpg",""];

  public imgIndex = 0;
  public control = false;
  public selectedImage: any;
  rightClick() {
    
    if (this.imgIndex < this.images.length - 1) {
      this.imgIndex = this.imgIndex + 1;
      this.control = true;
    }
    else {
      this.imgIndex = 0;
      this.control = true;
    }
    setTimeout(() => {
      this.control = false;
    }, 320);
      this.selectedImage = this.images[this.imgIndex];

    console.log(this.imgIndex)
  }
  leftClick() {
    if (this.imgIndex > 0) {
      this.imgIndex = this.imgIndex - 1;
      this.control = true;
    }
    else {
      this.imgIndex = this.images.length - 1;
      this.control = true;
    }
    setTimeout(() => {
      this.control = false;
    }, 320);
      this.selectedImage = this.images[this.imgIndex];
  }



}
